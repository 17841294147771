import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Spinner from "../../../../components/spinner";
import DetailsMetaData from "./DetailsMetaData";
import DetailsPerformance from "./DetailsPerformance";
import DetailsComments from "./DetailsComments";
import DetailsShare from "./DetailsShare";
import axios from "axios";
import config from "../../../../config.json";
import whiteLabel from "../../../../assets/whitelabelConfig";
import "../../../../Styles/style.css";

const Details = (props) => {
  const {id} = useParams();
  const [assetDetail, setassetDetail] = useState([]);
  const [activeTab, setActiveTab] = useState("Meta");
  const [isLoading, setisLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [formValid, setFormValid] = useState(false);
  const detail_Url = `${config.serviceUrl}/assets/get-asset-detail`;
  const [formData, setFormData] = useState({});

  function isVideo(item) {
    return item.endsWith("mov") || item.endsWith("mp4") || item.endsWith("avi");
  }

  const getassetDetail = () => {
    setisLoading(true);
    const authToken = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      id: id,
    };
    // console.log(body);
    axios
      .post(detail_Url, body, config)
      .then((response) => {
        setisLoading(false);
        setassetDetail(response.data);
        setFormData(response.data);
        // console.log("asset_detail_response:", response.data);
      })
      .catch((error) => {
        setisLoading(false);
        // console.error("Error fetching media platform data:", error);
      });
  };

  useEffect(() => {
    getassetDetail();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e, isDateChange = false) => {
    if (isDateChange) {
      const {id, value} = e;
      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    } else {
      const {id, value} = e.target;
      const dotIndex = id.indexOf(".");
      if (dotIndex !== -1) {
        // If the id contains a dot, it's a nested key
        const nestedKey = id.substring(0, dotIndex);
        const subKey = id.substring(dotIndex + 1);
        setFormData((prevState) => ({
          ...prevState,
          [nestedKey]: {
            ...prevState[nestedKey],
            [subKey]: value,
          },
        }));
      } else {
        // If the id doesn't contain a dot, it's a top-level field
        setFormData((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      }
    }
  };

  const renderMedia = (assetDetail, altImage, youtube_url = null) => {
    if (assetDetail.type === "text") {
      return (
        <div
        className="text-ad text-wrap text-truncate fs-4 vh-100"
        title={assetDetail.title}
       >
        {assetDetail.title}
      </div>
      );
    }
  
    if (assetDetail.sub_media_platform === "google_ads" && assetDetail.platform_specific_data?.youtube_url) {
      return (
        <iframe
          className="card-img-top"
          width="560"
          height="315"
          src={assetDetail.platform_specific_data.youtube_url}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
  
    switch (assetDetail.type) {
      case "audio":
        return assetDetail.gcs_file_url ? (
          <audio controls className="card-audio">
            <source src={assetDetail.gcs_file_url} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <img className="card-img-top" src={altImage} alt="img_name" />
        );
  
      case "video":
        return assetDetail.gcs_file_url ? (
          <video controls disablePictureInPicture className="card-video">
            <source src={assetDetail.gcs_file_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img className="card-img-top" src={altImage} alt="img_name" />
        );
  
      case "image":
        return (
          <img
          className="card-img-top"
          src={assetDetail.gcs_file_url || altImage}
          onError={(e) => { e.target.src = altImage; }}
          alt="img_name"
        />
        );
      
      default:
        return (
          <img
            className="card-img-top"
            src={assetDetail.gcs_thumbnail_url || altImage}
            onError={(e) => { e.target.src = altImage; }}
            alt="img_name"
          />
        );
    }
  };
  
  
  return (
    <div className="d-flex flex-column" onScroll={props.handleScroll}>
      <div className="position-relative text-white pb-4" style={{top: "58px"}}>
        {isLoading ? (
          <Spinner />
        ) : assetDetail.length !== 0 ? (
          <div className="container-fluid text-white d-flex align-items-center justify-content-center">
            <div className="col-12 mb-3">
              <div className="detail-container ui-sortable">
                <div className="panel-heading bg-black text-white p-2 rounded-top d-flex justify-content-between">
                  <div className="col-5">
                    <h4
                      className="panel-title d-inline-block text-truncate mt-1"
                      style={{maxWidth: "450px"}}>
                      {isVideo(assetDetail.type) ? (
                        <i className="bi bi-camera-video-fill me-1"></i>
                      ) : (
                        <i className="bi bi-card-image me-1"></i>
                      )}
                      <b>ASSET No. {assetDetail.id}</b>
                      {assetDetail.media_platform === "Non_Digital" &&
                        ` | ${
                          assetDetail.asset_name
                            ? assetDetail.asset_name
                            : "Unknown"
                        }`}
                      {assetDetail.title && `| ${assetDetail.title}`}
                    </h4>
                  </div>
                  {showSuccessMessage && (
                    <div className="col-7 d-flex justify-content-start fs-5">
                      <div className="alert alert-success p-2 m-0" role="alert">
                        {Message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="panel-body rounded-bottom px-3">
                  <div className="row my-3">
                    {/* LEFT REGION */}
                    <div className="col-lg-6 col-xl-6 border-end border-secondary">
                    {(assetDetail.type === "blog" && assetDetail.file_url) || (assetDetail.type === "link" && assetDetail.external_url) ? (
                        <button
                          className="btn btn-primary my-1"
                          onClick={() =>
                            window.open(assetDetail.type === "blog" ? assetDetail.file_url : assetDetail.external_url, "_blank")
                          }>
                          {assetDetail.type === "blog" ? "View Blog" : "Visit Link"}
                        </button>
                      ) : (
                        (assetDetail.type !== "link" && assetDetail.type !== "text" && assetDetail.type !== "blog" && !assetDetail.platform_specific_data?.youtube_url) && (
                          <a
                            className="btn btn-primary my-1"
                            href={`${assetDetail.gcs_file_url}?response-content-disposition=attachment`}
                            target="_blank"
                            rel="noreferrer">
                            Download Asset
                          </a>
                        )
                      )}
                      <div className="card custom-opacity border-2 p-2">
                      {renderMedia(assetDetail, whiteLabel.alt_image)}
                      </div>
                      {assetDetail.category !== "Paid" && (
                        <div className="Content-Interaction mt-3">
                          <h6>CONTENT INTERACTIONS:</h6>
                          <hr />
                          <div className="row-icons-bottom d-flex justify-content-center align-items-center mt-auto fs-6">
                            <span className="mx-5">
                              <i className="bi bi-heart-fill  me-2"></i>
                              {assetDetail.like_count}
                            </span>
                            <span className="mx-5">
                              <i className="bi bi-chat-fill me-2"></i>
                              {assetDetail.comment_count}
                            </span>
                            <span className="mx-5">
                              <i className="bi bi-share-fill me-2"></i>
                              {assetDetail.share_count}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="post-content mt-3">
                        <h6>META/POST CONTENT:</h6>
                        <hr />
                        {assetDetail.body}
                      </div>
                    </div>

                    {/* RIGHT REGION */}
                    <div className="col-lg-6 col-xl-6 ui-sortable">
                      <div className="row">
                        {/* NAVBAR PILLS */}
                        <div>
                          <ul className="nav nav-pills my-1">
                            <NavItem
                              component="Meta"
                              iconClass="bi-info-circle-fill"
                              activeTab={activeTab}
                              setActiveTab={setActiveTab}
                              text="Meta Data/Details"
                            />
                            <NavItem
                              component="Performance"
                              iconClass="bi-graph-up"
                              activeTab={activeTab}
                              setActiveTab={setActiveTab}
                              To={assetDetail}
                              text="Performance"
                            />
                            <NavItem
                              component="Comments"
                              iconClass="bi bi-chat-fill"
                              activeTab={activeTab}
                              setActiveTab={setActiveTab}
                              // To={assetDetail}
                              text="Comments"
                            />
                            <NavItem
                              component="Share"
                              iconClass="bi bi-share"
                              activeTab={activeTab}
                              setActiveTab={setActiveTab}
                              // To={assetDetail}
                              text="Share"
                            />
                          </ul>
                        </div>
                      </div>
                      {/* TAB PANE */}
                      <TabContent
                        image={assetDetail}
                        user={props.user.name}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleChange={handleChange}
                        setisLoading={setisLoading}
                        setShowSuccessMessage={setShowSuccessMessage}
                        showSuccessMessage={showSuccessMessage}
                        setShowErrorMessage={setShowErrorMessage}
                        showErrorMessage={showErrorMessage}
                        setMessage={setMessage}
                        Message={Message}
                        formValid={formValid}
                        setFormValid={setFormValid}
                        formData={formData}
                        getassetDetail={getassetDetail}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        ) : (
          <div className="container-fluid text-white d-flex align-items-center ">
            <h4>Data not found, Contact Admin</h4>
          </div>
        )}
      </div>
    </div>
  );
};

const NavItem = ({component, activeTab, setActiveTab, iconClass, text}) => (
  <li className="nav-item">
    <a
      href={`#nav-pills-tab-${component}`}
      data-bs-toggle="tab"
      className={`nav-link ${
        component === activeTab ? "active" : ""
      } text-white`}
      onClick={() => setActiveTab(component)} // Update the active tab when clicked
    >
      <span>
        {iconClass && <i className={`bi ${iconClass} me-2`}></i>}
        {text}
      </span>
    </a>
  </li>
);

const TabContent = ({
  image,
  user,
  activeTab,
  setActiveTab,
  handleChange,
  setisLoading,
  setShowSuccessMessage,
  showSuccessMessage,
  setShowErrorMessage,
  showErrorMessage,
  setMessage,
  Message,
  formValid,
  setFormValid,
  formData,
  getassetDetail,
}) => (
  <div className="tab-content custom-opacity p-3 panel rounded m-0">
    {/* Tab panes */}
    {["Meta", "Performance", "Comments", "Share"].map((tab, index) => (
      <div
        key={index}
        className={`tab-pane fade detailview-panel ${
          activeTab === tab ? "active show" : ""
        }`}
        id={`nav-pills-tab-${tab}`}>
        {getTabContent(
          tab,
          image,
          user,
          activeTab,
          setActiveTab,
          handleChange,
          setisLoading,
          setShowSuccessMessage,
          showSuccessMessage,
          setShowErrorMessage,
          showErrorMessage,
          setMessage,
          Message,
          formValid,
          setFormValid,
          formData,
          getassetDetail
        )}
      </div>
    ))}
  </div>
);

const getTabContent = (
  tab,
  assetDetail,
  user,
  activeTab,
  setActiveTab,
  handleChange,
  setisLoading,
  setShowSuccessMessage,
  showSuccessMessage,
  setShowErrorMessage,
  showErrorMessage,
  setMessage,
  Message,
  formValid,
  setFormValid,
  formData,
  getassetDetail
) => {
  if (!assetDetail) {
    return "No details Found";
  }
  switch (tab) {
    case "Meta":
      return (
        <DetailsMetaData
          assetDetail={assetDetail}
          formData={formData}
          getassetDetail={getassetDetail}
          handleChange={handleChange}
          formValid={formValid}
          setFormValid={setFormValid}
          setisLoading={setisLoading}
          setShowSuccessMessage={setShowSuccessMessage}
          showSuccessMessage={setShowSuccessMessage}
          setShowErrorMessage={setShowErrorMessage}
          showErrorMessage={showErrorMessage}
          setMessage={setMessage}
          Message={Message}
          user={user}
        />
      );
    case "Performance":
      if (assetDetail.media_platform !== "Non_Digital" && !assetDetail.insights) {
        return "No details Found";
      }
      return (
      <DetailsPerformance  
      assetDetail={assetDetail}
      formData={formData}
      getassetDetail={getassetDetail}
      handleChange={handleChange}
      setisLoading={setisLoading}
      setShowSuccessMessage={setShowSuccessMessage}
      setShowErrorMessage={setShowErrorMessage}
      setMessage={setMessage}
      />
      );
    case "Comments":
      return (
        <DetailsComments
          user={user}
          assetDetail={assetDetail}
          setisLoading={setisLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      );
    case "Share":
      return (
        <DetailsShare
          user={user}
          assetDetail={assetDetail}
          setisLoading={setisLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      );

    default:
      return null;
  }
};

export default Details;