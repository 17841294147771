import React from "react";
import {useLocation} from "react-router-dom";

function Sidebar(props) {
  const location = useLocation();

  return (
    <div className={`col-3 col-md-3 col-lg-2 border-secondary border-end text-white main-sessions ${props.isSideBar? 'sidebar-show' : 'sidebar-hide'} overflow-auto`}>
      <div style={{"height":"57px"}}></div> {/* Spacer */}      
      <ul className="nav flex-column">
        <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/assets") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("Assets")}>
            <i className="bi bi-images fs-5 me-1"></i>
            Assets
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/api") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("API")}>
              <i className="bi bi-diagram-3-fill fs-5 me-1"></i>
            API/Data Feeds
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/assetupload") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("AssetUpload")}>
              <i className="bi bi-folder-plus fs-5 me-1"></i>
            Media Upload
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/adminconsole") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("AdminConsole")}>
              <i className="bi bi-person-fill-gear fs-5 me-1"></i>
            Admin Console
          </button>
        </li>
        {/* <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/integrationmap") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("IntegrationMap")}>
              <i className="bi bi-map fs-5 me-1"></i>
            Integration Map
          </button>
        </li> */}
        <li className="nav-item">
          <button
            className={`nav-link text-secondary${
              location.pathname.includes("/journeymap") ? " text-white" : ""
            }`}
            onClick={() => props.handleNavigation("JourneyMap")}>
              <i className="bi bi-map fs-5 me-1"></i>
            Journey Maps
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
