const localisable = {
  metricFieldTooltipTitle: "Metric Fields",
  excellentScoreTooltipTitle: "threshold for excellent assets",
  goodScoreTooltipTitle: "threshold for good assets",
  averageScoreTooltipTitle: "threshold for average assets",
  lowScoreTooltipTitle: "threshold for low assets",
  replicateDataTooltipTitle: "Auto Populate Data to other metric fields",
  noDataAvailable: "No data available.",
  performanceMetric: "Performance Metric",
  incorrectScoreOrder: "Invalid score values, values are neither increasing nor decreasing order",
  copyMetricData: "Copy metrics data",
  twoDecimalPlaces: "Upto two decimal allowed",
  notSetYet: "Not set yet",
  notAvailable: "Not Available",
  notApplicableAbr: "N/A", // not applicable short form
  notApplicable: "Not Applicable",
};

export default localisable;
