export const excludedPerformanceKeys = {
    tradedesk: ['Campaign ID', 'Ad Group ID', 'Creative ID'],
    facebook: ['account_id', 'ad_id', 'adset_id', 'campaign_id'],
    instagram: ['account_id', 'ad_id', 'adset_id', 'campaign_id']
  };
  
export const excludedPlatformSpecificKeys = {
    facebook:['external_url'],
    instagram:['external_url'],
    linkedin:['content_landing_page'],
    google_ads:['display_url', 'final_urls', 'youtube_url'],
    youtube:['display_url', 'final_urls', 'youtube_url'],
    Non_Digital: ['audio', 'color_range', 'common_name', 'duration', 'encoding', 'file_owner', 'filepath'],
  };

export const topPerformanceKeys = {
  google_ads: ['ad_id', 'performance_start_date', 'performance_end_date', 'average_cpc', 'clicks', 'impressions', 'cost_micros', 'conversions', 'ctr', 'active_view_ctr', 'engagements', 'engagement_rate', 'active_view_cpm', 'average_cpm', 'average_cpe', 'average_cpv', 'interactions', 'interaction_rate' ],
  google_analytics: ['performance_start_date', 'performance_end_date' ],
  youtube: ['ad_id', 'performance_start_date', 'performance_end_date', 'average_cpc', 'clicks', 'impressions', 'cost_micros', 'conversions', 'ctr', 'active_view_ctr', 'engagements', 'engagement_rate', 'active_view_cpm', 'average_cpm', 'average_cpe', 'average_cpv', 'interactions', 'interaction_rate' ],
};

export const topPlatformKeys = {
  google_ads: ['ad_id','ad_group_ad_status','ad_group_id','ad_group_name','ad_group_status','ad_group_type','ad_id','ad_network_type','ad_type','campaign_id','campaign_name','campaign_start_date','campaign_end_date'],
  youtube: ['ad_id','ad_group_ad_status','ad_group_id','ad_group_name','ad_group_status','ad_group_type','ad_id','ad_network_type','ad_type','campaign_id','campaign_name','campaign_start_date','campaign_end_date'],
};